<template>
  <div class="holder">
    <h1 class="animated fadeInDown">Ops</h1>
    <img
      src="../assets/images/notfound.svg"
      alt="conteúdo não encontrado"
      class="animated zoomIn"
      style="animation-delay:250ms;"
    />
    <p class="animated fadeIn">
      Não encontramos o que você estava procurando. Talvez o conteúdo tenha
      mudado de nome ou tenha sido removido.
    </p>
    <router-link
      to="/home"
      class="btn animated fadeInUp"
      style="animation-delay:500ms;"
    >
      <span>Voltar ao início</span>
    </router-link>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.holder {
  position: relative;
  width: 100vw;
  max-width: 540px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  h1 {
    font-size: 2em;
  }
  img {
    position: relative;
    max-width: 100%;
    margin: 10px auto 35px auto;
  }
  p {
    position: relative;
    text-align: center;
    font-size: 1.15em;
    margin: 0 25px;
  }
  .btn {
    margin-top: 35px;
  }
}
</style>
